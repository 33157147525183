@import '../../../../../../Foundation/Styling/code/thule/global/media-queries.less';
@import '@{variablesLocation}';

.editorialBoxWithImage {
  content-visibility: auto;
  padding-bottom: 3rem;

  img {
    width: 100%;
  }

  &__text {
    margin-top: 1.5rem;

    hr {
      margin: 1.6rem 0;
      width: 25%;
    }

    .desc {
      color: @Gray80;

      * {
        color: @Gray80;
        margin: 0;
        font-size: 1.6rem;
      }

      a {
        text-decoration: underline;
      }
    }
  }

  button {
    width: 100%;

    @media screen and (min-width: @screen-md) {
      max-width: 390px;
    }
  }
}

@media screen and (min-width: @screen-lg) {
  .editorialBoxWithImage {
    padding-bottom: 4rem;
    padding-top: 4rem;

    &__media {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 0;
    }

    &--align-right {
      .editorialBoxWithImage__media {
        order: 1;
      }

      .editorialBoxWithImage__text {
        order: 2;
      }
    }

    &--align-left {
      .editorialBoxWithImage__media {
        order: 2;
      }

      .editorialBoxWithImage__text {
        order: 1;
      }
    }
  }
}
.editorialBoxWithImage.bg-color--black {
  color: @White;
}
